import React from "react";
import "./App.css";

interface CashBidsConfigWindow extends Window {
  companySlug: string;
  width: number;
}

declare global {
  interface Window {
    cashBidsWidgetConfig: CashBidsConfigWindow;
  }
}

function App() {
  // window.cashBidsWidgetConfig = window.cashBidsWidgetConfig || {
  //   companySlug: "bushelsalesdemo",
  //   width: 1104,
  // };
  return <div id="root"></div>;
}

export default App;
